import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import DisplayText from './components/DisplayText.js';
import { useParams } from 'react-router-dom';

//TODO:recaptachaKey
const recaptchaKey = '6LfLuvQoAAAAADfRWpFUUM2aiIBQnoh0Rm9gAGzY';

const choices = [
  { text: "J'ai oublié la date mais je souhaite m'inscrire pour la prochaine JPO", preciser: false },
  { text: "J'ai eu un contretemps mais je souhaite m'inscrire pour la prochaine JPO", preciser: false },
  { text: "J'ai suffisamment d'informations sans venir à la JPO", preciser: false },
  {
    text: "Finalement le programme Année lumière ne m'intéresse pas car j'ai trouvé un autre projet (formation, emploi, voyage...)",
    preciser: true,
  },
  { text: 'Autre', preciser: true },
];

const defaultChoice = 0;

const FormPpvenu = () => {
  const [formData, setFormData] = useState({
    choices:"J'ai oublié la date mais je souhaite m'inscrire pour la prochaine JPO",
    select: defaultChoice,
    precision: choices[defaultChoice].preciser ? '' : choices[defaultChoice].text,
    eventID: 0,
  });
  // Init variables
  const [inputErrors, setInputErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [isRecaptchaValid, setIsRecaptchaValid] = useState(false);
  const [showPreciser, setShowPreciser] = useState(false);

  const { keygen } = useParams();

  const validateForm = () => {
    const errors = {};

    const { select, precision, certify } = formData;

    const choice = choices[select];

    if (!handleRecaptchaChange) errors.robot = 'Veuillez cocher la case "Je ne suis pas un robot"';
    if (!choice) errors.select = 'Veuillez sélectionner une réponse';
    if (showPreciser && !precision) errors.precision = 'Veuillez préciser votre réponse';
    if (!certify) errors.certify = 'Veuillez accepter la politique de protection des données';

    setInputErrors(errors);

    if (Object.keys(errors).length) {
      toast.error(`Il y a ${Object.keys(errors).length} erreurs dans le formulaire, veuillez les corriger`, {
        position: 'top-center',
        autoClose: 1400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!validateForm()) return;
    setIsSubmit(1);

    try {
      const url = 'https://xdjj-vqgo-2lfl.f2.xano.io/api:YSHYreNv/anneelumiere_contacts/ppvenu/' + keygen;

      const data = { precision: formData.precision !== "" ? formData.choices + " : " + formData.precision : formData.choices };

      const response = await axios.patch(url, data);

      if (response.status === 200) {
        setIsSubmit(3);
        setTimeout(() => setShowPopup(true), 1000);
      } else {
        setIsSubmit(2);

        toast.error('Erreur lors de la soumission, veuillez ressayer', {
          isLoading: false,
          position: 'top-center',
          autoClose: 1400,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Erreur lors de la soumission, vous n'êtes pas autorisé");
      } else {
        toast.error('Erreur lors de la soumission, veuillez ressayer');
      }

      setIsSubmit(2);
    }

    if (isSubmit !== 0 || !isRecaptchaValid) {
      return; // Prevent form submission if already submitting or reCAPTCHA is invalid
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: e.target.checked, // Store the checked value for checkboxes
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: DOMPurify.sanitize(value),
      }));
    }
  };
  const handleChoiceChange = (e) => {
    const { name, value } = e.target;
    const choice = choices[value];

    if (!choice) return;

    setShowPreciser(choice.preciser);

    setFormData((prevFormData) => ({
      ...prevFormData,
      ["choices"]: choice.text,
    }));
  };

  const handleRecaptchaChange = (value) => {
    // Store the reCAPTCHA response value in the component's state
    setIsRecaptchaValid(!!value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      handleRecaptchaChange: value,
    }));
  };

  const reloadLocation = () => {
    setShowPopup(false);
    window.location.reload();
  };

  return (
    <div id="form-sos" onSubmit={handleSubmit}>
      <ToastContainer />
      <div className="form-container">
        <div className="form-row">
          <div className="col100">
            <div className="columnWrap">
              <div className="widgetWrap">
                <div id="form-titleContainer">
                  <div className="widgetContainer">
                    <h1>
                      <a href="">
                        <img
                          className="logoAL"
                          src="https://www.annee-lumiere.org/wp-content/uploads/2021/07/AnneeLumiere-logo.png"
                        ></img>
                      </a>
                    </h1>
                  </div>
                </div>

                <div id="form-formContainer">
                  <div className="widgetContainer">
                    <form method="post" name="Formulaire Problème de venue">
                      <div className="form-wrapper">
                        {/* Fields here */}
                        <div className="form-input col80">
                          <label className="obligatoire">
                          Afin de nous permettre de mieux nous organiser, merci de nous indiquer les raisons de
                            votre absence
                          </label>
                          <select
                            className="selectStyle"
                            placeholder="précisez votre réponse"
                            name="choices"
                            defaultValue={defaultChoice}
                            onChange={handleChoiceChange}
                            required
                          >
                            {choices.map(({ text }, index) => (
                              <option key={index} value={index}>
                                {text}
                              </option>
                            ))}
                          </select>

                          {showPreciser && (
                            <div className="form-input col70 marginUp">
                              <input
                                placeholder="Précisez votre réponse"
                                type="autre"
                                name="precision"
                                value={formData.champLibre_CConnu}
                                onChange={handleInputChange}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      {/* CAPTCHA */}
                      <div className="form-input marginUp" style={{ marginLeft: '28px' }}>
                        {isSubmit !== 3 ? (
                          <ReCAPTCHA
                            sitekey={recaptchaKey}
                            name="handleRecaptchaChange"
                            onChange={handleRecaptchaChange}
                          />
                        ) : (
                          <></>
                        )}
                        {inputErrors.robot && <div className="redError">{inputErrors.robot}</div>}
                      </div>

                      {/* Je certifie avoir pris connaissances... */}
                      <div className="form-input checkboxContainer2">
                        <div className="field-group">
                          <span className="field-option">
                            <input
                              className="field-checkbox"
                              type="checkbox"
                              name="certify"
                              id="certify"
                              value="Je certifie avoir pris connaissance de la Politique de protection des données et l’accepte en tous termes"
                              onChange={handleInputChange}
                            />
                            <label for="certify" className="field-notes" id="autorite">
                              Je certifie avoir pris connaissance de la{' '}
                              <a
                                id="rgpd"
                                target="_blank"
                                href="https://www.annee-lumiere.org/privacy-policy/"
                                rel="noopener noreferrer"
                              >
                                Politique de protection des données
                              </a>{' '}
                              et l’accepte en tous termes
                            </label>
                          </span>
                        </div>
                        {inputErrors.certify && <div className="redError">{inputErrors.certify}</div>}
                      </div>

                      {/* Envoie du formulaire */}
                      <div className="btn-submit">
                        <button
                          type="submit"
                          disabled={formData.handleRecaptchaChange === '' ? true : false || !isRecaptchaValid}
                          className={isSubmit !== 0 ? 'isSubmit' : ''}
                        >
                          {isSubmit === 1
                            ? 'Envoie de la réponse en cours de traitement... ne quittez pas la page'
                            : isSubmit === 2
                              ? "Echec de l'envoie, veuillez reessayer"
                              : isSubmit === 3
                                ? 'Réponse envoyée !'
                                : 'Envoyer'}
                        </button>

                        {/*<button type="submit" className={isSubmit !== 0 ? ('isSubmit'):('')}>{isSubmit === 1 ? ('Demande en cours de traitement... ne quittez pas la page'): isSubmit === 2 ?('Echec de la demande, veuillez reessayer'):isSubmit === 3 ?('Demande envoyée !'):("Envoyer")}</button>*/}
                      </div>
                    </form>
                    {showPopup && (
                      <div className="popup">
                        <div className="popup-content">
                          <h3>Votre réponse à bien été enregistrée !</h3>
                          {/* <p>
                            Vous allez recevoir prochainement un e-mail de confirmation de prise en charge de votre
                            inscription.
                          </p> */}

                          <button onClick={() => reloadLocation()}>Fermer</button>
                          <div className="rdv">
                            <span>
                              rendez-vous sur{' '}
                              <a rel="noreferrer noopener" href="https://www.annee-lumiere.org/">
                                annee-lumiere.org
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="cgv">
                    <div className="widgetContainer">
                      <p>
                        Les données signalées par un * sont obligatoires. Vos données à caractère personnel sont
                        collectées par ANNEE LUMIERE pour son intérêt légitime dans le but de traiter votre demande.
                        Vous disposez des droits suivants : droit d’accès, droit de rectification, droit à l’effacement
                        (« droit à l’oubli »), droit à la limitation et droit d’opposition. Pour exercer vos droits,
                        vous êtes invités à contacter ANNEE LUMIERE aux coordonnées suivantes :{' '}
                        <span className="redEdilians">contact@annee-lumiere.org</span> Pour plus d’information sur le
                        traitement de vos données à caractère personnel, vous pouvez consulter notre politique en
                        cliquant{' '}
                        <span className="redEdilians">
                          <a
                            className="redEdilians"
                            href="https://www.annee-lumiere.org/privacy-policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            ici
                          </a>
                        </span>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormPpvenu;
